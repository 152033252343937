import * as cheerio from 'cheerio';
import { isValidHttpUrl } from '../helpers/utils';
import { getVariableParser } from '../modules/variableParser';
import { getFormulaParser } from '../modules/formulaParser';
let expressionParser = null;
export const createExpressionParser = () => {
    /**
     * Parses raw expression to value in following ways (controlled via mode attribute):
     ** unwrap - removes only html wrapper element from parsed value
     ** strip - removes all nested html from the parsed value
     ** skip - no processing is performed on parsed value
    */
    const parse = async (rawExpression, mode) => {
        if (!rawExpression) {
            return {
                value: rawExpression,
                isPlainText: true
            };
        }
        const variableParser = getVariableParser();
        const formulaParser = getFormulaParser();
        const parsedVariable = await variableParser.parse(rawExpression);
        const parsedValue = await formulaParser.parse(parsedVariable);
        if (typeof parsedValue.value === 'string') {
            parsedValue.value = parsedValue.value.trim();
            if (mode === 'unwrap') {
                parsedValue.value = unwrap(parsedValue.value);
            }
            else if (mode === 'strip' || (mode === undefined && parsedValue.isPlainText)) {
                parsedValue.value = stripHtml(parsedValue.value);
            }
        }
        parsedValue.isPlainText = !!parsedValue.isPlainText;
        return parsedValue;
    };
    /**
     * Returns the inner text after removing the outer paragraph html element
    */
    const unwrap = (parsedExpression) => {
        let formattedText = parsedExpression || '';
        if (parsedExpression && parsedExpression.startsWith('<p>') && parsedExpression.endsWith('</p>')) {
            formattedText = formattedText.substring(3, parsedExpression.length - 4);
        }
        formattedText = formattedText.trim();
        return formattedText;
    };
    /**
     * Returns text after removing all html elements
    */
    const stripHtml = (parsedExpression) => {
        let formattedText = parsedExpression || '';
        formattedText = formattedText.replace(/<[^>]*>?/gm, '').trim();
        return formattedText;
    };
    /**
     * parses expression inside an an element's attribute value
    */
    const parseElementAttribute = async (elementMeta, rawExpression) => {
        if (rawExpression) {
            const htmlDoc = cheerio.load(rawExpression);
            const body = htmlDoc.root().find('body');
            const elements = body.find(`${elementMeta.selector}[${elementMeta.attribute}]`);
            for (const element of elements) {
                if (element.attribs[elementMeta.attribute] && element.attribs[elementMeta.attribute].startsWith('<p>')) {
                    const { value } = await parse(element.attribs[elementMeta.attribute], 'strip');
                    if (typeof value === 'string') {
                        element.attribs[elementMeta.attribute] = value;
                    }
                    if (elementMeta.attribute === 'href' && isValidHttpUrl(element.attribs[elementMeta.attribute])) {
                        // element.attribs[elementMeta.attribute] = encodeURI(element.attribs[elementMeta.attribute]);
                        element.attribs[elementMeta.attribute] = element.attribs[elementMeta.attribute].trim().replaceAll(' ', '%20');
                    }
                }
            }
            return body.html();
        }
        return rawExpression;
    };
    expressionParser = {
        parse,
        unwrap,
        stripHtml,
        parseElementAttribute
    };
    return getExpressionParser();
};
export const getExpressionParser = () => {
    if (expressionParser === null) {
        throw '@nuclicore/core: Expression parser is not configured';
    }
    else {
        return expressionParser;
    }
};
