import { computed, ref, watch } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { fetchDeploymentsService } from '@/services/build-service/deploymentRequests';
import { APPLICATION_STORE } from '@/store/common/storeConstants';
import { getExpressionParser } from '@nuclicore/core';

export const useApplicationStore = defineStore(APPLICATION_STORE, () => {
    const uploadedFiles = ref({});
    const deletedUploads = ref({});
    const getDeletedUploads = computed(() => deletedUploads.value ? Object.keys(deletedUploads.value) : []);
    
    /** @type {import('@/store/types/applicationStore').ExecutionResult} */
    const executionResult = ref({});
    const shouldUpdateForm = ref(true);
    const shouldUseEagerValidations = ref(false);
    const currentNodeId = computed(() => executionResult.value?.current_id);
    const currentNode = computed(() => deploymentInfo.value.data.find((node) => node.nodeId === currentNodeId.value));

    /** @type {import('@/store/types/applicationStore').DeploymentInfo} */
    const deploymentInfo = ref({});
    const fetchDeploymentInfo = async (id) => {
        try {
            const { data: { data } } = await fetchDeploymentsService(id);
            deploymentInfo.value = data;
        } catch (err) {
            console.error(err);
        }
    };

    watch(
        () => currentNodeId.value,
        async () => {
            let result = false;
            const currentScreenInfo = deploymentInfo.value.data.find((node) => node.nodeId === currentNodeId.value);
            if (currentScreenInfo) {
                if (currentScreenInfo.data?.isEagerFormValidationDynamic) {
                    const expressionParser = getExpressionParser();
                    result = !!((await expressionParser.parse(currentScreenInfo.data?.hasEagerFormValidation)).value);
                } else {
                    result = !!currentScreenInfo.data?.hasEagerFormValidation;
                }
            }
            shouldUseEagerValidations.value = result;
        }
    );

    return {
        uploadedFiles,
        deletedUploads,
        getDeletedUploads,
        executionResult,
        currentNodeId,
        currentNode,
        deploymentInfo,
        shouldUpdateForm,
        shouldUseEagerValidations,
        fetchDeploymentInfo
    };
});
