import moment from 'moment-timezone';
import { getExpressionParser } from '../../modules/expressionParser';
export const createDateArithmeticParser = () => {
    const expressionParser = getExpressionParser();
    const getDateDifference = (firstDate, secondDate, activeUnits) => {
        const diff = {};
        activeUnits.forEach(unit => {
            const unitAsType = unit;
            diff[unit] = firstDate.diff(secondDate, unitAsType);
            secondDate.add(diff[unit], unitAsType);
        });
        return diff;
    };
    const parse = async (data) => {
        var _a, _b;
        let first_date;
        let second_date;
        // parse units
        const firstDateUnits = (_a = data.data.first_date) === null || _a === void 0 ? void 0 : _a.units;
        const secondDateUnits = (_b = data.data.second_date) === null || _b === void 0 ? void 0 : _b.units;
        if (firstDateUnits) {
            data.data.first_date.units = (await expressionParser.parse(firstDateUnits, 'strip')).value;
        }
        if (secondDateUnits) {
            data.data.second_date.units = (await expressionParser.parse(secondDateUnits, 'strip')).value;
        }
        if (data.data.first_date.type === 'Custom') {
            first_date = moment.tz((await expressionParser.parse(data.data.first_date.date, 'strip')).value, data.data.first_date.timezone);
        }
        else {
            first_date = getDateFromType(data.data.first_date);
        }
        if (data.data.second_date.type === 'Custom') {
            second_date = moment.tz((await expressionParser.parse(data.data.second_date.date, 'strip')).value, data.data.second_date.timezone);
        }
        else {
            second_date = getDateFromType(data.data.second_date, first_date);
        }
        if (data.data.result.type === 'Custom') {
            const customFormat = (await expressionParser.parse(data.data.result.customFormat, 'strip')).value;
            let resultString = customFormat;
            const units = {
                Y: 'years',
                M: 'months',
                D: 'days',
                h: 'hours',
                m: 'minutes',
                s: 'seconds'
            };
            const unitsList = Object.entries(units);
            const activeUnits = unitsList
                .filter(([unitSymbol]) => customFormat.includes(`(${unitSymbol})`))
                .map((unit) => unit[1]);
            const differences = getDateDifference(first_date, second_date, activeUnits);
            unitsList.forEach(([unitSymbol, unitValue]) => {
                if (unitValue in differences) {
                    resultString = resultString.replace(new RegExp(`\\(${unitSymbol}\\)`, 'g'), differences[unitSymbol].toString());
                }
            });
            return {
                value: resultString,
                isPlainText: true
            };
        }
        if (data.data.result.type === 'Date') {
            return {
                value: second_date.format(data.data.result.format),
                isPlainText: true
            };
        }
        return {
            value: `${first_date.diff(second_date, data.data.result.units.toLowerCase())}`,
            isPlainText: true
        };
    };
    const getDateFromType = (dateData, referenceDate) => {
        switch (dateData.type) {
            case 'Yesterday':
                return moment.tz(dateData.timezone).subtract(1, 'days');
            case 'Tomorrow':
                return moment.tz(dateData.timezone).add(1, 'days');
            case 'Days from today':
                return moment.tz(dateData.timezone).add(Number(dateData.units), 'days');
            case 'Days before today':
                return moment.tz(dateData.timezone).subtract(Number(dateData.units), 'days');
            case 'Months from today':
                return moment.tz(dateData.timezone).add(Number(dateData.units), 'months');
            case 'Months before today':
                return moment.tz(dateData.timezone).subtract(Number(dateData.units), 'months');
            case 'Years from today':
                return moment.tz(dateData.timezone).add(Number(dateData.units), 'years');
            case 'Years before today':
                return moment.tz(dateData.timezone).subtract(Number(dateData.units), 'years');
            case 'Days from first date':
                return referenceDate.clone().add(Number(dateData.units), 'days').tz(dateData.timezone);
            case 'Days before first date':
                return referenceDate.clone().subtract(Number(dateData.units), 'days').tz(dateData.timezone);
            case 'Months from first date':
                return referenceDate.clone().add(Number(dateData.units), 'months').tz(dateData.timezone);
            case 'Months before first date':
                return referenceDate.clone().subtract(Number(dateData.units), 'months').tz(dateData.timezone);
            case 'Years from first date':
                return referenceDate.clone().add(Number(dateData.units), 'years').tz(dateData.timezone);
            case 'Years before first date':
                return referenceDate.clone().subtract(Number(dateData.units), 'years').tz(dateData.timezone);
            default:
                return moment.tz(dateData.timezone);
        }
    };
    return {
        parse
    };
};
