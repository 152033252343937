let sessionClient = null;
export const createSessionClient = (sessionClientConfig) => {
    sessionClient = { ...sessionClientConfig };
    return getSessionClient();
};
export const getSessionClient = () => {
    if (sessionClient === null) {
        throw '@nuclicore/core: session client is not configured';
    }
    return sessionClient;
};
