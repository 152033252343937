import { getExpressionParser } from '../../modules/expressionParser';
import moment from 'moment-timezone';
export const createTransformTimezoneParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        let parsedValue = '';
        const { value } = await expressionParser.parse(data.data.date.value, 'strip');
        const first = moment.tz(value, data.data.date.fromTz).format();
        if (data.data.date.format === 'Custom') {
            const { value: customFormat } = await expressionParser.parse(data.data.date.customFormat, 'strip');
            parsedValue = moment.tz(first, data.data.date.toTz).format(customFormat);
        }
        else if (data.data.date.format === 'ISO Date') {
            parsedValue = moment.tz(first, data.data.date.toTz).format();
        }
        else {
            parsedValue = moment.tz(first, data.data.date.toTz).format(data.data.date.format);
        }
        return {
            value: parsedValue,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
