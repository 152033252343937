import { getExpressionParser } from '../../modules/expressionParser';
import moment from 'moment-timezone';
export const createNowParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        let value = '';
        if (data.data.format === 'Custom') {
            value = moment.tz(data.data.timezone).format((await expressionParser.parse(data.data.customFormat, 'strip')).value);
        }
        else {
            value = moment().tz(data.data.timezone).format(data.data.format);
        }
        return {
            value,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
