import moment from 'moment-timezone';
import { getExpressionParser } from '../../modules/expressionParser';
export const createDateFormatterParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { inputDate, outputDate } = data.data;
        const { value: inputDateString } = await expressionParser.parse(inputDate.date, 'strip');
        let inputDateFormat = inputDate.format === 'Custom' ? (await expressionParser.parse(inputDate.customFormat, 'strip')).value : inputDate.format;
        if (inputDateFormat === 'Number') {
            inputDateFormat = 'x';
        }
        const parsedInputDate = moment(inputDateString, inputDateFormat);
        let outputDateFormat = inputDateFormat;
        if (outputDate.result === 'Date') {
            outputDateFormat = outputDate.format === 'Custom' ? (await expressionParser.parse(outputDate.customFormat, 'strip')).value : outputDate.format;
        }
        else if (outputDate.result === 'Number') {
            outputDateFormat = 'x';
        }
        return {
            value: parsedInputDate.format(outputDateFormat),
            isPlainText: true
        };
    };
    return {
        parse
    };
};
